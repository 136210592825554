// src/pages/Home.js

import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home">
      <h1 className="neon-title">Dice | Mods</h1>
      <p className="neon-subtitle">by DonnieDice</p>
      <a href="/mods" className="neon-button">Browse Mods</a>
    </div>
  );
}

export default Home;
