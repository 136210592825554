// src/pages/About.js

import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-page">
      <div className="about-container">
        {/* Page Header */}
        <header className="about-header">
          <h1 className="about-title">🎲 About Dice | Mods</h1>
          <p className="about-subtitle">
            Enhancing your <strong>World of Warcraft</strong> adventures with meticulously crafted addons.
          </p>
        </header>
        
        {/* Spacer */}
        <div className="header-spacer"></div>

        {/* Introduction Section */}
        <section className="about-section">
          <h2 className="section-title">🎮 Who is DonnieDice?</h2>
          <p className="section-text">
            Hey there! I’m <strong>Joey</strong>, also known as <strong>DonnieDice</strong> — a passionate <strong>World of Warcraft</strong> player and addon developer.
          </p>
          <p className="section-text">
            My addons are designed to streamline gameplay, improve efficiency, and enhance immersion. Audio addons, in particular, play a crucial role in enriching the gaming experience by modifying default sounds, adding cues for important events, and creating an immersive soundscape that keeps players engaged and informed.
          </p>
        </section>

        {/* What Makes Dice | Mods Unique */}
        <section className="about-section">
          <h2 className="section-title">🛠️ What Makes Us Different?</h2>
          <p className="section-text">
            At <strong>Dice | Mods</strong>, each addon is crafted with attention to detail and a passion for improving gameplay experiences. Whether it’s enhancing soundscapes, optimizing user interfaces, or adding useful gameplay features, our goal is to make every WoW session smoother and more engaging.
          </p>
          <ul className="features-list">
            <li>🔄 <strong>Regular Updates:</strong> Always compatible with the latest patches and expansions.</li>
            <li>📚 <strong>Clear Documentation:</strong> Easy-to-follow setup guides.</li>
            <li>🌐 <strong>Seamless Downloads:</strong> Direct and hassle-free access to addons.</li>
          </ul>
        </section>

        {/* Community Section */}
        <section className="about-section">
          <h2 className="section-title">🤝 Join Our Community</h2>
          <p className="section-text">
            The heart of <strong>Dice | Mods</strong> lies in our community. Whether you want to share ideas, report bugs, or just hang out with fellow players, every voice is valued.
          </p>
          <p className="section-text">
            Join the discussion on our forums or connect with us on Discord.
          </p>
        </section>

        {/* My Modding Journey */}
        <section className="about-section">
          <h2 className="section-title">👾 My Modding Journey</h2>
          <p className="section-text">
            I began modding games in <strong>2002</strong> with <strong>Diablo 2 PvP mods</strong>. One of my standout projects involved transforming the <strong>Burial Grounds</strong> into a dedicated PvP arena. I created a one-way portal inside an <strong>Act 1 tent</strong> that led players directly into this intense battleground, offering thrilling and unforgettable PvP experiences.
          </p>
          <p className="section-text">
            Today, I bring that same passion and creativity to crafting addons for <strong>World of Warcraft</strong>.
          </p>
        </section>

        {/* Contact Section */}
        <section className="about-section contact-section">
          <h2 className="section-title">📬 Get in Touch</h2>
          <p className="section-text">
            Have questions, feedback, or just want to say hi? Drop me an email at
            <a href="mailto:joseph@dicematrix.cloud" className="contact-link">
              joseph@dicematrix.cloud
            </a>.
          </p>
        </section>

        {/* Footer */}
        <footer className="about-footer">
          <p>
            Thanks for being part of the <strong>Dice | Mods</strong> community. Together, let’s make Azeroth even more legendary!
          </p>
        </footer>
      </div>
    </div>
  );
}

export default About;
