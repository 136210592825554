// src/pages/Mods.js

import React, { useEffect, useState } from 'react';
import './Mods.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Mods() {
  const [mods, setMods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMods = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/mods`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMods(data);
      } catch (error) {
        console.error('Error fetching mods:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMods();
  }, []);

  if (loading) {
    return <div className="loading">Loading mods...</div>;
  }

  if (error) {
    return <div className="error">Error loading mods: {error.message}</div>;
  }

  return (
    <div className="mods-container">
      {mods.map((mod) => (
        <div key={mod._id} className="mod-card">
          <img src={mod.imageUrl} alt={mod.name} className="mod-image" />
          <div className="mod-content">
            <h2 className="mod-title" title={mod.name}>{mod.name}</h2>
            <p className="mod-description">{mod.description}</p>
            <div className="mod-info">
              <div className="mod-details">
                <p className="mod-author">by {mod.author}</p>
                <p className="mod-version">Version: {mod.version}</p>
                <p className="mod-release-date">
                  Released on: {new Date(mod.releaseDate).toLocaleDateString()}
                </p>
              </div>
              <a
                href={mod.downloadLink}
                className="mod-download-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Mods;
