// src/components/NavBar.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <nav className="navbar">
        {/* Logo */}
        <div className="logo-container">
          <img src={`${process.env.PUBLIC_URL}/blu_logo.png`} alt="BLU Logo" className="logo-image" />
          <h1 className="logo-text">Dice | Mods</h1>
        </div>

        {/* Mobile Menu Toggle */}
        <div className="menu-toggle" onClick={toggleMenu}>
          ☰
        </div>

        {/* Navigation Links */}
        <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>

          {/* Mods Dropdown */}
          <li className="dropdown">
            <span className="dropdown-toggle">Mods ▾</span>
            <ul className="dropdown-menu">
              <li><Link to="/mods" onClick={toggleMenu}>All Mods</Link></li>
              <li><Link to="/blu" onClick={toggleMenu}>BLU | Better Level-Up!</Link></li>
            </ul>
          </li>

          <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default NavBar;
